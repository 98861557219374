import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Nav } from "react-bootstrap";
import {
  faTags,
  faTruck,
  faBoxesStacked,
  faChartLine,
  faChartSimple,
  faRotateLeft,
} from "@fortawesome/free-solid-svg-icons";
import { faShopify } from "@fortawesome/free-brands-svg-icons";
import Link from "next/link";

const Navigation = () => {
  return (
    <Nav
      as="ul"
      className="nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start"
    >
      <Nav.Item as="li">
        <Link href="/" className="nav-link align-middle px-0">
          <FontAwesomeIcon icon={faTruck} />{" "}
          <span className="ms-1 d-none d-sm-inline">Bestellungen</span>
        </Link>
      </Nav.Item>

      <Nav.Item as="li">
        <Link href="/Batches" className="nav-link align-middle px-0">
          <FontAwesomeIcon icon={faTags} />{" "}
          <span className="ms-1 d-none d-sm-inline">Chargen / Etiketten</span>
        </Link>
      </Nav.Item>

      <Nav.Item as="li">
        <Link href="/ShopifyOrders" className="nav-link align-middle px-0">
          <FontAwesomeIcon icon={faShopify} />{" "}
          <span className="ms-1 d-none d-sm-inline">tarabao.bio</span>
        </Link>
      </Nav.Item>

      <Nav.Item as="li">
        <Link href="/PurchaseOrders" className="nav-link align-middle px-0">
          <FontAwesomeIcon icon={faBoxesStacked} />{" "}
          <span className="ms-1 d-none d-sm-inline">Warenannahme</span>
        </Link>
      </Nav.Item>

      <Nav.Item as="li">
        <Link href="/DepositReturns" className="nav-link align-middle px-0">
          <FontAwesomeIcon icon={faRotateLeft} />{" "}
          <span className="ms-1 d-none d-sm-inline">Pfandrückgabe</span>
        </Link>
      </Nav.Item>

      <Nav.Item as="li">
        <Link href="/SalesCharts" className="nav-link align-middle px-0">
          <FontAwesomeIcon icon={faChartLine} />{" "}
          <span className="ms-1 d-none d-sm-inline">Auftragsanalyse</span>
        </Link>
      </Nav.Item>

      <Nav.Item as="li">
        <Link href="/StockAnalysis" className="nav-link align-middle px-0">
          <FontAwesomeIcon icon={faChartSimple} />{" "}
          <span className="ms-1 d-none d-sm-inline">Warenanalyse</span>
        </Link>
      </Nav.Item>
    </Nav>
  );
};

export default Navigation;
