import React from "react";
import { Dropdown } from "react-bootstrap";
import LoginForm from "../components/LoginForm";
import { AuthContext } from "../utils/AuthProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const Accounts = () => {
  const authContext = React.useContext(AuthContext);

  return (
    <>
      <span className="d-none d-md-inline">ERP:</span>
      <Dropdown className="pb-4">
        <Dropdown.Toggle
          style={{ fontSize: ".8rem" }}
          className="text-white text-decoration-none button-outline"
        >
          {Object.keys(authContext.getAuth()).length > 0 && (
            <>
              <span className="d-none d-md-inline">
                {
                  Object.entries(authContext.getAuth()).find(
                    (entry) => entry[1].active
                  )![0]
                }
              </span>
              <span className="d-md-none">✓</span>
            </>
          )}
          {Object.keys(authContext.getAuth()).length == 0 && (
            <>
              <span className="d-none d-md-inline">niemand eingeloggt</span>
              <span className="d-md-none">
                <FontAwesomeIcon icon={faTimes} />
              </span>
            </>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu
          className="dropdown-menu-dark text-small shadow"
          style={{ minWidth: "18rem" }}
        >
          <LoginForm loginFunction={authContext.erpLogin} name="ERP" />
          <Dropdown.Divider />
          {Object.entries(authContext.getAuth()).map((user) => (
            <Dropdown.Item
              active={user[1].active}
              className="d-flex justify-content-between px-3"
              key={user[0]}
            >
              <span onClick={() => authContext.setActive(user[0])}>
                {user[0]}
              </span>
              <span onClick={() => authContext.erpLogout(user[0])}>
                <FontAwesomeIcon icon={faTimes} style={{ color: "red" }} />
              </span>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default Accounts;
