import { Col, Container, Form, Navbar, Row } from "react-bootstrap";
import React, { ReactElement } from "react";
import Navigation from "../components/Navigation";
import Accounts from "./Accounts";

export default function Layout({ children }: { children: ReactElement }) {
  const [printer, setPrinterRaw] = React.useState("L805");

  const setPrinter = (value: string) => {
    setPrinterRaw(value);
    localStorage.setItem("printer", value);
  };

  React.useEffect(() => {
    setPrinterRaw(localStorage.getItem("printer") || "L805");
  }, []);

  return (
    <Container fluid>
      <Row className="flex-nowrap">
        <Col xs="auto" md={3} xl={2} className="px-0 bg-dark no-print">
          <div className="d-flex flex-column align-items-center align-items-sm-start px-sm-3 pt-2 text-white min-vh-100">
            <Navigation />
            <hr />
            <Accounts />
          </div>
        </Col>
        <Col className="px-0 vh-100 vh-100-print overflow-auto">
          <Navbar
            variant="light"
            bg="light"
            sticky="top"
            style={{ position: "sticky" }}
            className="no-print"
          >
            <div className="container-fluid px-0">
              <div className="d-flex align-items-center justify-content-end mt-1 mb-2 my-lg-0">
                <Container>
                  <Form.Select
                    value={printer}
                    onChange={(e) => setPrinter(e.target.value)}
                  >
                    <option value="L805">Drucker 1</option>
                    <option value="L8052">Drucker 2</option>
                  </Form.Select>
                </Container>
              </div>
            </div>
          </Navbar>
          <Container className="p-2">{children}</Container>
        </Col>
      </Row>
    </Container>
  );
}
