import { AuthContextType, AuthType } from "./AuthProvider";

const rawQuery = ({
  uri,
  method,
  headers,
  body,
  auth,
  user,
}: {
  uri: string;
  method: string;
  headers?: { [name: string]: string };
  body?: string;
  auth: AuthType;
  user?: string;
}): any => {
  let token: string;
  if (user) token = auth[user].token;
  else token = Object.values(auth).find((user) => user.active)!.token;

  return fetch(`${process.env.NEXT_PUBLIC_ERPNEXT_URL}/api/${uri}`, {
    method: method,
    headers: {
      Authorization: `Bearer ${token}`,
      ...headers,
    },
    body: body,
  });
};

export const erpQuery = async ({
  uri,
  method,
  headers,
  body,
  authContext,
  auth,
  setAuth,
  user,
}: {
  uri: string;
  method: string;
  headers?: { [name: string]: string };
  body?: string;
  authContext?: AuthContextType;
  auth?: AuthType;
  setAuth?: (
    user: string,
    token: string,
    refreshToken: string,
    active?: boolean
  ) => void;
  user?: string;
}): Promise<any> => {
  if (authContext) {
    auth = authContext.getAuth();
    setAuth = authContext.setAuth;
  }
  if (!auth) throw "No auth given";

  // TODO: set authentication status if we receive a 403
  return rawQuery({ uri, method, headers, body, auth, user }).then((res) => {
    if (res.status == 403)
      // 403 if not authenticated
      return refreshToken(auth, setAuth, user)
        .then(() => {
          auth = authContext.getAuth();
          rawQuery({ uri, method, headers, body, auth, user });
        })
        .then((res) => (res.status == 200 ? res.json() : false));
    return res.json();
  });
};

const refreshToken = async (
  auth: AuthType,
  setAuth: (
    user: string,
    token: string,
    refreshToken: string,
    active?: boolean
  ) => void,
  user?: string
) => {
  if (user) var userAuth = auth[user];
  else {
    var userAuth = Object.values(auth).find((user) => user.active)!;
    user = Object.entries(auth).find((entry) => entry[1].active)![0];
  }

  const params = new URLSearchParams({
    grant_type: "refresh_token",
    refresh_token: userAuth.refreshToken,
    client_id: "bd12d079eb",
  });

  await fetch(
    `${process.env.NEXT_PUBLIC_ERPNEXT_URL}/api/method/frappe.integrations.oauth2.get_token`,
    {
      method: "POST",
      body: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${userAuth.token}`,
      },
    }
  ).then(async (res) => {
    if (res.status != 200) return false;
    let interm = await res.json();
    setAuth(user!, interm.access_token, interm.refresh_token);
  });
};

export const getBatches = async (
  itemCode: string,
  warehouse: string,
  authContext: AuthContextType
) => {
  /*
  Get all batches for a certain Item, filter by expiry_date. Then, get stock qty of each batch and
  discard all batches with 0 qty.
  */
  let batches: { name: string; qty: number; uom: string }[] = [];

  let todayDate = new Date();
  let dd = String(todayDate.getDate()).padStart(2, "0");
  let mm = String(todayDate.getMonth() + 1).padStart(2, "0");
  let yyyy = todayDate.getFullYear();
  let today = yyyy + "-" + mm + "-" + dd;

  const params = new URLSearchParams();
  params.append("doctype", "Batch");
  params.append("query", "erpnext.controllers.queries.get_batch_no");
  params.append(
    "filters",
    JSON.stringify({
      item_code: itemCode,
      posting_date: today,
      warehouse: warehouse,
    })
  );
  params.append("reference_doctype", "Sales Invoice Item");
  params.append("txt", "");

  let batchQuery = await erpQuery({
    uri: `method/frappe.desk.search.search_link`,
    authContext,
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: params.toString(),
  });

  await Promise.all(
    batchQuery.results.map(async (batch) => {
      let batchParams = new URLSearchParams({
        batch_no: batch.value,
        warehouse: warehouse ?? "Helmbrechts - BFD",
      });
      let batchQuery = await erpQuery({
        uri: "method/erpnext.stock.doctype.batch.batch.get_batch_qty",
        authContext,
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: batchParams.toString(),
      });

      batches.push({
        name: batch.value,
        qty: batchQuery.message, //parseFloat(batch.description.split(',')[0]),
        uom: batch.description.split(",")[1].trim(),
      });
    })
  );

  return batches;
};
