import { Formik } from "formik";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";

interface LogInProps {
  name: string;
  loginFunction: (email: string, password: string) => Promise<boolean>;
}

const LoginForm = (props: LogInProps) => {
  return (
    <div className="p-3">
      <h5>Login {props.name}</h5>
      <Formik
        initialValues={{ email: "", password: "" }}
        onSubmit={async (
          values: {
            email: string;
            password: string;
          },
          actions
        ) => {
          const success = await props.loginFunction(
            values.email,
            values.password
          );
          if (!success) {
            actions.setStatus(false);
          }
          actions.setSubmitting(false);
          actions.setValues({ email: "", password: "" });
        }}
      >
        {({
          values,
          handleSubmit,
          handleChange,
          isSubmitting,
          status,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group>
                <Form.Label>E-Mail</Form.Label>
                <Form.Control
                  type="text"
                  required
                  name="email"
                  autoComplete="email"
                  value={values.email}
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group>
                <Form.Label>Passwort</Form.Label>
                <Form.Control
                  type="password"
                  required
                  name="password"
                  autoComplete="password"
                  value={values.password}
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>
            <Button type="submit" className="mt-3" disabled={isSubmitting}>
              {isSubmitting && <Spinner animation="border" size="sm" />}
              Login
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default LoginForm;
