import "../styles/main.scss";
import type { AppProps } from "next/app";
import dynamic from "next/dynamic";
import AuthProvider from "../utils/AuthProvider";
import React, { ReactElement, ReactNode } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import Layout from "../components/Layout";
import { NextPage } from "next";

export type NextPageWithLayout = NextPage & {
  getLayout?: ({ children }: { children: ReactElement }) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const [queryClient] = React.useState(() => new QueryClient());
  //const Page = Component.getLayout ? Component.getLayout(<Component {...pageProps} />) :
  const MyLayout = Component.getLayout ?? Layout;

  return (
    <>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <MyLayout>
            <Component {...pageProps} />
          </MyLayout>
        </QueryClientProvider>
      </AuthProvider>
    </>
  );
}

export default dynamic(() => Promise.resolve(MyApp), { ssr: false });
