import * as React from "react";
import { erpQuery } from "./erpClient";

export type AuthType = {
  [user: string]: { token: string; refreshToken: string; active: boolean };
};

interface IState {
  initialized: boolean;
}

export type AuthContextType = {
  state: IState;
  checkAuth: () => Promise<void>;
  erpLogout: (user: string) => Promise<boolean>;
  erpLogin: (email: string, password: string) => Promise<boolean>;
  setActive: (email: string) => boolean;
  setAuth: (
    email: string,
    token: string,
    refreshToken: string,
    active?: boolean
  ) => void;
  getAuth: () => AuthType;
};

let AuthContext = React.createContext<AuthContextType>({
  state: {
    initialized: false,
  },
  checkAuth: async () => {},
  erpLogout: (user: string): Promise<boolean> => new Promise(() => true),
  erpLogin: async (email: string, password: string): Promise<boolean> => false,
  setActive: (email: string): boolean => true,
  setAuth: (
    email: string,
    token: string,
    refreshToken: string,
    active?: boolean
  ): void => {},
  getAuth: () => ({}),
});

export default class AuthProvider extends React.Component<{}, IState> {
  constructor(props: {}) {
    super(props);

    this.state = {
      initialized: false,
    };

    this.checkAuth = this.checkAuth.bind(this);
    this.setActive = this.setActive.bind(this);
    this.erpLogout = this.erpLogout.bind(this);
    this.erpLogin = this.erpLogin.bind(this);
    this.setAuth = this.setAuth.bind(this);
    this.getAuth = this.getAuth.bind(this);
  }

  async componentDidMount() {
    // pages in customerArea call queryAuth immediately to perform a necessary redirect
    // if this has been done already, don't do it again.
    if (!this.state.initialized) this.checkAuth();
  }

  getAuth = () => {
    if (typeof localStorage == "undefined") return {};
    return JSON.parse(localStorage.getItem("auth") ?? "{}") as {
      [user: string]: { token: string; refreshToken: string; active: boolean };
    };
  };

  setAuth = (
    user: string,
    token: string,
    refreshToken: string,
    active?: boolean
  ) => {
    let auth = this.getAuth();

    if (active == undefined) active = auth[user]?.active ?? false;

    if (active) for (let email of Object.keys(auth)) auth[email].active = false;

    auth[user] = { token, refreshToken, active };
    localStorage.setItem("auth", JSON.stringify(auth));
  };

  removeAuth = (user: string) => {
    let auth = this.getAuth();
    delete auth[user];
    localStorage.setItem("auth", JSON.stringify(auth));
  };

  erpLogout = async (user: string): Promise<boolean> => {
    const params = new URLSearchParams({
      token: this.getAuth()[user].token,
    });
    await fetch(
      `${process.env.NEXT_PUBLIC_ERPNEXT_URL}/api/method/frappe.integrations.oauth2.revoke_token`,
      {
        method: "POST",
        body: params,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    this.removeAuth(user);
    return new Promise(() => true);
  };

  erpLogin = async (email: string, password: string): Promise<boolean> => {
    const params = new URLSearchParams({
      grant_type: "password",
      response_type: "token",
      username: email,
      password: password,
      client_id: "bd12d079eb",
      scope: "all openid",
    });

    let erpQuery = await fetch(
      `${process.env.NEXT_PUBLIC_ERPNEXT_URL}/api/method/frappe.integrations.oauth2.get_token`,
      {
        method: "POST",
        body: params,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );

    if (erpQuery.status != 200) return false;

    let interm = await erpQuery.json();
    this.setAuth(email, interm.access_token, interm.refresh_token, true);

    this.checkAuth();
    return true;
  };

  setActive = (user: string) => {
    let auth = this.getAuth();

    if (!Object.keys(auth).includes(user)) return false;

    for (let authedUser of Object.values(auth)) authedUser.active = false;
    auth[user].active = true;

    localStorage.setItem("auth", JSON.stringify(auth));

    return true;
  };

  queryAuthERP = async (auth: AuthType) => {
    try {
      let loginQuery = await erpQuery({
        uri: "method/frappe.auth.get_logged_user",
        method: "GET",
        setAuth: () => {},
        auth,
      });
      if (!loginQuery) throw "Not authenticated";

      return true;
    } catch (e) {
      return false;
    }
  };

  checkAuth = async () => {
    let auth = this.getAuth();

    if (Object.keys(auth).length > 0) {
      let currentActiveAuth = Object.entries(auth).find(
        (entry) => entry[1].active
      );
      let currentActive: string;
      if (!currentActiveAuth) currentActive = Object.keys(auth)[0];
      else currentActive = currentActiveAuth[0];

      auth[currentActive].active = false;

      for (let user of Object.keys(auth)) {
        auth[user].active = true;
        localStorage.setItem("auth", JSON.stringify(auth));

        if (!this.queryAuthERP(auth)) delete auth[user];
        else auth[user].active = false;
      }

      if (!Object.keys(auth).includes(currentActive)) {
        if (Object.keys(auth).length > 0)
          auth[Object.keys(auth)[0]].active = true;
      } else auth[currentActive].active = true;

      localStorage.setItem("auth", JSON.stringify(auth));
    }
    this.setState({ initialized: true });
  };

  render() {
    return (
      <AuthContext.Provider
        value={{
          state: this.state,
          checkAuth: this.checkAuth,
          erpLogout: this.erpLogout,
          erpLogin: this.erpLogin,
          setActive: this.setActive,
          setAuth: this.setAuth,
          getAuth: this.getAuth,
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

let AuthContextConsumer = AuthContext.Consumer;

export { AuthContext, AuthContextConsumer };
